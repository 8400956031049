import { graphql, useStaticQuery } from 'gatsby'

export const useWorkNodes = () => {
  const {
    data: { nodes },
  } = useStaticQuery(
    graphql`
      query AllWorkNodesQuery {
        data: allSitePage(filter: { path: { regex: "/^/node/.+/" } }, sort: { fields: context___title }) {
          nodes {
            path
            context {
              key
              tag
              title
            }
          }
        }
      }
    `
  )

  return nodes
}
